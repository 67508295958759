/* purgecss start ignore */

#rhs {
	float: right;
	margin-top: 0;
	width: 330px;
	margin-right: -350px;

	.articles-index & {
		margin-top: 20px;
	}


	@media (max-width: 767px) {
		display: none;
		float: none;
		margin-top: 0px;
		width: auto;
		margin-right: -0px;
	}

	ul {
		list-style-type: none;
		margin-left: 0;
	}
	.ad-block {}

	.med-rect {
		padding: 30px 0 10px 0;
		text-align: center;
	}

	.med-rect>div {
		margin: 0 auto;
	}

	.headlines {
		display: flex !important;
		flex-flow: row wrap;
		justify-content: space-between;
		width: 300px;
		padding: 4px 5px 4px 0px;
		margin-top: 20px;
	}

	.headlines h4 {
		text-align: center;
		width: 100%;
		margin-bottom: -5px;
		margin-left: -5px;
		border: 1px solid #ccc;
		border-width: 1px 0;
		text-transform: uppercase;
		line-height: 22px;
		margin-bottom: 8px;
	}

	.camera-list h4 {
		margin-bottom: 0;
		margin-left: -5px;
	}

	.headlines {
		p {
			clear: both;
			font-size: 16px;
			line-height: 1.4;
			margin-bottom: 15px;
			text-align: center;
			width: 45%;
			a {
				display: block;
				margin-top: 10px;
				img {
					margin: 10px auto;
					display: block;
				}
			}
		}
		ul {
			margin-bottom: 10px;
			margin-left: 15px;
			margin-top: 5px;
			list-style-type: square;
			li {
				font-size: 14px;
				line-height: 1.6;
				margin-bottom: 10px;
				padding-left: 0px;
				color: #999;
			}
		}

	}

	.date {
		color: darkred;
		font-size: 10px;
		font-weight: normal;
		margin-left: 5px;
		margin-right: 5px;
	}

	.sky-1 {
		margin-bottom: 20px;
	}

	.affiliates {
		padding: 20px;
		margin-top: 20px;
		font-size: 11px;
		color: #999;
	}

	.content-ad-unit a {
		text-decoration: none;
		color: #000;
	}
}


.ad-block.skyscraper {
	margin-top: 4px;
}


#rhs-col-wrap {
	.left {
		float: left;
		width: 170px;
	}

	.right {
		float: right;
		width: 160px;
	}

}


/* RHS tabbed content nav */

.top-content {
	margin-top: 20px;
}

#top-content-tabs h2 {
	font-size: 1.5rem !important;
	letter-spacing: .01rem;
	line-height: 1;
}

.top-content__header {
	font-size: 1.125rem;

	display: flex;
}

	.top-content__tab {
		width: 50%;
		display: block;
		a {
			background-color: #f5f5f5;
			border-right: 2px solid #fff;
			color: #666;
			display: flex;
			padding: 5px 10px;
			opacity: .5;
			&:hover,&:focus {
				opacity: 1;
			}
		}
		#content & {
			a {
				text-decoration: none;
			}
		}
	}
	.top-content__tab.active a {
		opacity: 1;
		background-color: #f0f0f0;
	}

 .top-content__pane {
	padding: 7px 0;
	min-height: 400px;
 }

	 .top-content__item {
		padding: 10px 0;
	 }
		 .top-content__item a {
			color: #2a3c4e;
			font-size: 16px;
			font-weight: 700;
			display: flex;
			align-items: center;
			padding-top: 5px;
			text-decoration: none;
			transition: color 0.05s ease-in-out;
	 		&:hover {
	 			color: red;
	 			text-decoration: none;
	 		}
		 }

		 .top-content__item__img-wrap {
			width: 100px;
			text-align: center;
			margin-right: 10px;
		 }

			 .top-content__item img {
				margin-top: -5px;
				 &.thumb-portrait {
					max-height: 120px;
					width: auto;
				 }
			 }

/* purgecss end ignore */