/* ------------------------------------------------------------
		Entry details and stars
------------------------------------------------------------ */

.entry-details {
	color: #666;
	font-size: 17px;
	text-align: center;
	margin-bottom: 20px;
	margin-top: 7px;
	font-weight: normal;
	.reviewer a {
		font-weight: normal !important;
		text-decoration: none !important;
	}

	b {
		margin: 0 3px;
		color: #ddd;
		font-weight: normal;
	}

	.stars {
		display: inline-block;
		color: gold;
		margin-top: 2px;
	}
	.sharethis {
		margin-left: -7px;
		padding-left: 10px;
		display: inline-block;
		vertical-align: middle;
	}

	.sharethis a {
		color: #666;
	}

	.twitter-share-button {
		float: left;
	}

	.fb-like {
		display: inline;
	}

	@media (max-width: 767px) {
		margin-top: 3px !important;

		.sharethis {
			margin-left: 0;
			padding-left: 0;
			margin-top: 7px;
		}
		a {
			display: inline-block;
		}

		.vdiv, b {
			color: #fff;
			margin: 0 1px;
		}
	}

}

@media (max-width: 767px) {

	.entry-title-wide {
		width: auto !important;
	}

}

.stars svg,
.rating-drop-link svg {
	width: 20px;
	height: 20px;
	margin-right: -5px;
	vertical-align: sub;
}
