/* ------------------------------------------------------------
	Single entry pages
------------------------------------------------------------ */

#content p.return {
	margin: 10px 0 0 0px;
	font-weight: bold;
}

.share-horiz-block {
	margin-top: 10px;
}


img.entry-image,
figure img {
	margin: 20px auto;
	display: block;
	height: auto;
	max-width: 100%;
	@media (min-width: 550px) {
		max-width: 550px;
	}
}

img.entry-image.img-portrait {
	max-height: 375px;
	width: initial;
}

/* News galleries */

.mini-gallery {
	box-sizing: border-box;
	width: 100%;
	margin-bottom: 20px;
	background-color: #333;
	padding: 15px;
	clear: both;
	column-count: 2;
	column-gap: 15px;

	@media (min-width: 614px) {
		column-count: 3;
	}

	a {
		display: block;
		margin-bottom: 10px;
		&:hover {
			cursor: pointer;
		}
	}
		img {
			width: 100%;
			height: auto;
		}
}


/* Comments */

#comments {
	margin-top: 20px;
	margin-bottom: 20px;
	clear: both;
}

.scripts1 {
		display: none;
}


a#comment-order-toggle .old {
		display: none;
}

@media (max-width: 767px) {

	#comment-form textarea {
		max-width: 100%;
		box-sizing: border-box;
	}

	/*comments*/
	#comment-form {
		margin: 0 auto;
		max-width: 400px;
		input,
		textarea {
			font-size: 13px;
		}
		p.submit {
			text-align: center;
			margin-top: 20px;
		}
	}

	.comment {
		a {
			word-break: break-word;
			hyphens: auto;
		}

		.comment-count a {
			font-size: 14px;
		}

	}

	h3.comments-header .vdiv {
		font-weight: normal;
		opacity: .5;
	}

}

@media (max-width: 400px) {

	h3.comments-header {
		font-size: 15px;
		padding-left: 0 !important;
		.vdiv {
			font-weight: normal;
			margin: 0 0px;
			font-size: 12px;
		}
	}

	#comment-form textarea {
		width: 100%;
	}

}


/* ------------------------------------------------------------
		Review pages
------------------------------------------------------------ */

/* Pre review content */

body.introduction {
	h3 em {
			font-weight: bold;
			font-size: 21px;
			opacity: 0.35;
			padding-right: 6px;
	}

	.prereview-image {
			margin: 70px auto 20px auto;
			display: block;
			max-height: 400px;
			width: auto;
			max-width: 550px;
	}

}

.feature-img {
		max-width: 100%;
		height: auto;
}

p.caption {
		text-align: center;
		font-weight: bold;
}

/* Image quality */

table div.header {
		margin-bottom: 7px;
}

#content table p.header {
		margin-bottom: 7px;
}

table.image-quality-images {
		width: 100%;
}

#content table.image-quality-images {
	th {
			padding: 0;
		p {
				margin-bottom: 0;
		}
	}

	td {
		text-align: center;
		padding: 5px;
		p {
				margin-bottom: 7px;
		}

		&+td p {
				margin-bottom: 7px;
				margin-top: 4px;
		}

	}
	td {
	}

	p {
			font-weight: bold;
	}

}


/* Sample images */

body.sample_images {
	#content table {
		td {
				text-align: center;
				padding-bottom: 20px;
		}
		tbody td {
				padding-bottom: 5px;
		}
	}
	@media (max-width: 479px) {

		.content-inner p+table td,
		.content-inner h2+table td {
			width: 100%;
			border: 0px solid green;
			display: block;
		}

	}
}

/* PG table fix */

body.sample_images #content .pricegrabber-search table td {
		text-align: left;
		padding-bottom: 0px;
}

#content p.exif {
		text-align: center;
		font-size: 14px;
		color: #666;
}

table.raw-images img {
		margin-bottom: 20px;
}

#content table.raw-images p.exif {
		margin-top: -20px;
}

p.movie-link {
		font-size: 19px;
		font-weight: bold;
		margin-top: -10px;
}

/* Image grids */

.sample-images-gallery {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		margin: 0 -5px;
}
		.si-item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			max-width: 140px;
			padding: 0 5px;
			@media (min-width: 768px) {
				max-width: 150px;
			}
		}

.si-img-wrap {
		position: relative;
		overflow: hidden;
		margin: 0 auto 10px;
		max-width: 550px;
		img {
			margin: 0 auto;
			display: block;
			width: 100%;
			height: auto;
		}
		a {
				text-decoration: none !important;
		}
}

.sample-movie {
		margin-bottom: 1em;
		.si-overlay-text-inner svg {
				width: 2em;
				height: 2em;
		}
}
		.sample-movie-download-link {
				font-size: 16px;
				text-align: center;
		}

a.si-overlay {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: block;
	background-color: rgba(0, 0, 0, 0);
	color: #fff;
	opacity: 0;
	transition: all 0.3s ease-out;
	.si-img-wrap:hover & {
			background-color: rgba(0, 0, 0, .4);
			cursor: pointer;
			transition: all 0.3s ease-out;
			opacity: 1;
	}
}


.si-overlay-text {
		display: table;
		vertical-align: middle;
		width: 100%;
		height: 100%;
}

.si-overlay-text-inner {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	svg {
			display: block;
			margin: 0 auto;
			width: 20px;
			height: 20px;
			fill: currentColor;
	}
}


/* Lens review */

table.image-samples td {
		text-align: center;
		padding-bottom: 20px;
}


/* Camera preview */

table.camera-preview {
	td {
			text-align: center;
			padding-bottom: 20px;
	}
	img {
			margin-bottom: 20px;
	}
	#content & {
			margin-bottom: 0;
			p.exif {
					margin-top: -20px;
					margin-bottom: 0;
			}
	}
}


/* Specifications */

.specifications-table {
	margin-top: 20px;
	overflow: hidden;
	padding-top: 20px;
	table {
		border-collapse: collapse;
		td {
			padding: 5px;
			line-height: 22px;
			padding-bottom: 10px;
			word-wrap: break-word;
		}

		table {
			margin-top: -20px;
			td {
				word-wrap: break-word;
				top: -4px;
				padding: 10px;
				border-bottom: 1px solid #ccc;
			}
		}

	}

}

/* Conclusion */

p.star-rating {
	text-align: center;
	clear: both;
	margin-left: auto;
	margin-right: auto;
	padding: 10px 0 0px 0;
	max-width: 300px;

	@media (max-width: 400px) {
		img {
			margin: 0 auto;
		}
	}
}

table.ratings {
	margin: 0 auto;
	border-collapse: collapse;
	width: 300px;
	margin-bottom: 50px !important;

	@media (max-width: 400px) {
		width: 290px;
	}
	th {
			border: 1px solid #999;
			padding: 14px 10px;
			background: #0e1012;
			font-size: 19px;
			color: #fff;
			text-transform: uppercase;
	}

	td {
		padding: 10px 16px;
		font-weight: bold;
		border: 1px solid #ccc;
		background-color: #fff;
		font-size: 19px;
		&.label {
				text-align: ;
				padding-left: 20px;
		}

		&.alt {
				background-color: #f7f7f7;
		}

		&.score {
				text-align: center;
		}
	}

}

/* Review roundup */

body.review_roundup {
	.entry {
			margin-left: 23px;
	}

	h3 {
			margin-left: -23px;
	}

	a.external {
		text-decoration: none !important;
		img {
				padding-right: 5px;
				margin-left: -2px;
				text-decoration: none;
		}
	}


}

p.sharethis-wrap {
	font-style: italic;
	a {
			font-weight: bold;
			font-style: normal;
			margin: 5px 5px;
			display: block;
			float: left;
	}
}


h3.share-header {
		margin-bottom: 5px;
		clear: both;
		margin-top: 20px;
		text-transform: capitalize;
}

.share-text {
	margin-bottom: 20px;
	.twitter-share-button {
			float: left;
	}

	.fb-like {
			vertical-align: top;
			float: left;
	}

	p {
			font-size: 19px;
			float: left;
			width: 360px;
			font-style: italic;
			margin: 0 0 15px 10px;
			display: block;
	}
}


.fb-like {
		padding-left: 8px;
}


.share-blocks {
	margin: 35px 0;

	@media (max-width: 400px) {

		display: table;
		margin-left: -12px;
		margin-right: -12px;
		width: auto;

	}

	a {
		display: inline-block;
		width: 200px;
		height: 50px;
		color: #fff;
		font: 20px/1 "Arial Narrow", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
		text-align: center;
		line-height: 50px;
		text-decoration: none !important;
		position: relative;

		@media (max-width: 400px) {
			display: table-cell;
			border-left: 1px solid #fff;
			&:first-of-type {
				border-left: none;
			}
		}

		&.facebook {
			background-color: #3B5998;
			margin-right: 2px;
		}

		&.twitter {
			background-color: #00aced;
			margin-right: 2px;
		}

		&.comment {
			background-color: #0e1012;
		}
	}

	svg {
		width: 20px;
		height: 20px;
	}

	@media (max-width: 767px) {
		display: table;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		&:after {
			display: none;
		}
		a {
			display: table-cell;
			border-left: 2px solid #fff;
			font-size: 15px;
			&:first-of-type {
				border-left: none;
			}
		}
	}

}


.ad-slot-article-footer {
		margin-top: 20px;
}


#content p.compare {
		margin-bottom: 20px;
}


.one-page {
	>h2,
	#comments>h2 {
			padding-top: 80px !important;
	}

	>h2:first-of-type {
			margin-top: 0;
			padding-top: 0 !important;
			border-top: 0;
	}

}
