/* ------------------------------------------------------------
		Basic type
------------------------------------------------------------ */

h1, h2, h3, h4 {
		padding: 5px 0;
		text-align: center;
		letter-spacing: -1px;
		font-family: "Arial Narrow", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

h1 {
		font-size: 70px;
		font-weight: bold;
		text-align: left;
		letter-spacing: -3px;
		line-height: 80px;
		margin-bottom: 0;
		text-align: center;
		@media (max-width: 767px) {
			font-size: 40px;
			line-height: 1.1;
			letter-spacing: 0;
		}
}

h2 {
		text-align: left;
		font-size: 36px !important;
}

h3 {
		font-size: 26px;
		text-align: left;
		letter-spacing: 0px !important;
}

h3.entry-header {
		font-size: 24px;
}

h4 {
		letter-spacing: 0;
}

h4,
h5,
h6 {
		font-size: 23px;
		text-align: left;
		letter-spacing: 0px !important;
}

#content {
	h2 {
			font-size: 26px;
			padding: 0 0 8px 0;
	}
	h2.subhead {
			margin-top: -1px;
			margin-bottom: -10px;
			color: #777;
			font-size: 19px;
			float: left;
	}
	ul,
	ol {
			margin-left: 20px;
			margin-bottom: 20px;
	}

	ul li,
	ol li {
			margin-bottom: 2px;
	}

	p {
			line-height: 1.6;
			margin-bottom: 20px;
	}
	table {
			margin-bottom: 20px;
	}
}


@media (max-width: 767px) {

	.content-inner {
		h2 {
			text-align: center;
			font-size: 34px !important;
			letter-spacing: 0;
		}
		h3 {
			text-align: center;
		}
	}

	a[href^="/tag/"] {
		font-size: 12px;
	}

}