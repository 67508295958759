/* ------------------------------------------------------------
	Entry lists
------------------------------------------------------------ */

#content h2 {
	&.category-header {
		margin-bottom: -6px;
		padding: 0;
		text-align: center;
		text-transform: uppercase;
		color: #999;
		margin-top: 13px;
		span {
			display: block;
			font-size: 20px;
			letter-spacing: 1px;
			color: #CCC;
			margin-bottom: -5px;
		}
	}

	&.date-header {
		margin-bottom: -6px;
		padding: 0;
	}

}

.entry-list {
	.date, h2 {
		font-size: 24px;
		line-height: 1.1;
		text-align: center;
	}
	.date {
		border: 1px solid #ddd;
		color: #999;
		border-width: 1px 0;
		font-weight: bold;
		letter-spacing: 1px !important;
		margin: 25px 0 20px 0;
		padding: 10px 0;
		text-transform: uppercase;
	}
	.entry + .date {
		border-top: 0;
		margin-top: 0;
	}

	h2 {
		margin-bottom: 10px;
		a {
			font-size: 54px;
			letter-spacing: -1px;
			#content & {
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}

		}
	}
	.entry > a,
	.entry > a>img {
		display: block;
		margin: 0 auto;
	}

	.entry > a>img {
		margin-bottom: 1em;
	}

	.category-header ~ .entry {
		border-bottom: 0;
	}

	.entry {
		border-bottom: 1px solid #ddd;
		padding-bottom: 30px;
		+ .entry {
			margin-top: 30px;
		}
	}

	div.subhead {
		color: #777;
		font-size: 19px;
		margin: -5px 0 20px 1px;
		text-align: center;
	}

	div.subhead a {
		font-weight: normal;
	}

	.entry {
		.details {
			text-align: center;
			margin-top: 0px;
			color: #999;
			margin-right: 5px;
			a {
				font-weight: bold;
			}
			.fb-like {
				display: inline-block;
				vertical-align: middle;
				margin-top: 2px;
			}

			.twitter-share-button {
				display: inline;
				vertical-align: middle;
			}
		}

		.subhead .share {
			display: inline-block;
			vertical-align: middle;
			margin: -7px 0 0 10px;
		}

	}


	.mpu {
		text-align: center;
		margin-bottom: 20px;
		>div {
			margin: 0 auto;
		}
	}

}

@media (max-width: 767px) {

	.entry-list h3.date {
		font-size: 20px;
	}

	#content .entry-list h2 a {
		font-size: 35px;
		line-height: 1.2;
	}
	img.entry-image {
		&.img-portrait {
			max-height: 275px;
			width: initial;
		}
	}

}

img.productimage {
	float: left;
	margin-right: 10px;
	margin-bottom: 10px;
	.entry-list & {
		float: none;
		margin: 15px auto 10px auto;
		display: block;
	}
}