/* ------------------------------------------------------------
    General
------------------------------------------------------------ */

* {
    margin: 0;
    padding: 0;
}

body {
    background: #fff;
    color: #000;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 19px;
    min-width: 984px;
    overflow-x: hidden;
}

a {
    color: #2a3c4e;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a:focus,
a:active {
    outline-style: none;
}

#content a {
    font-weight: bold;
    text-decoration: underline;
}

#content a:hover {
    text-decoration: none;
}

.to-left {
    float: left;
}

.to-right {
    float: right;
}

.vdiv {
    color: #ccc;
    margin: 0 2px;
}

.clear {
    clear: both;
}

.clearfix:after {
    content: ".";
    clear: both;
    height: 0;
    visibility: hidden;
    display: block;
}

.clearfix {
    display: block;
}

iframe.content-iframe {
    width: inherit !important;
}

.geo-uk {
    display: none;
}

/*
  Screen reader only
*/
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.justify-between {
    justify-content: space-between;
}

.text-right {
    text-align: right;
}

.mobile-only {
    display: none !important;
}

.desktop-only {
    display: block !important;
}

.whitespace-nowrap {
    white-space: nowrap;
}

@media (max-width: 767px) {
    .mobile-only {
        display: block !important;
    }
    .desktop-only {
        display: none !important;
    }
    html {
        -webkit-text-size-adjust: 100%;
    }

    body {
        min-width: 0;
    }
    span[data-gg-vcache] {
        display: none !important;
    }
    .row {
        margin-bottom: 20px;
        width: 100%;
    }
}