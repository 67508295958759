/* ------------------------------------------------------------
	Layout - overall
------------------------------------------------------------ */

#header-wrap,
#content-wrap,
#footer {
	margin: 0 auto;
	width: 984px;
  @media (max-width: 767px) {
    width: auto;
  }
}

#content-wrap {
	padding: 0 12px;
}


#content {
	float: left;
	margin: 10px 0 0 0;
	width: 634px;
}

.entry-title-wide {
  width: 984px;
}

.content-inner {
	float: left;
	width: 614px;
}

@media (max-width: 767px) {
  #content {
      float: none;
      margin: 10px 0px 0 0;
      width: auto;
  }
  .content-inner {
      float: none;
      width: auto;
  }
}


.leaderboard {
    background: #0e1012;
}

.entry+#nointelliTXT h3 {
    margin-top: 60px;
}

.row {
    margin-bottom: 20px;
    width: 100%;
  &:before,
  &:after {
      content: " ";
      display: table;
  }
  &:after {
      clear: both;
  }
}


.pswp__caption__center {
    text-align: center;
}