/* ------------------------------------------------------------
    PAGINATION
------------------------------------------------------------ */

.next-page-wrap {
    margin-top: 50px;
    margin-bottom: 25px;
    .next-page {
        margin-bottom: 10px !important;
        margin: 0 auto;
    }
    @media (max-width: 767px) {
        display: table;
        margin-bottom: 30px;
        width: 100%;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        a {
            display: table-cell;
            width: 50%;
            background-color: #0e1012;
            vertical-align: top;
            padding: 15px 20px;
            box-sizing: border-box;
            &:first-of-type:last-of-type {
                width: 50% !important;
                display: block;
                height: 63px;
            }
            @media (max-width: 400px) {
                display: table-cell;
                width: 50%;
                background-color: #0e1012;
                vertical-align: top;
                padding: 12px 10px;
            }
        }
        &:after {
            display: none;
        }

        a:first-of-type {
            border-right: 3px solid #fff;
        }
        .next-page {
            margin-bottom: 0 !important;
        }
    }
    body.introduction & {
        a {
            @media (max-width: 767px) {
                float: right;
                border-right: 0;
            }
        }

    }
}

p.page-navigation-tips {
    text-align: center;
    font-style: italic;
    width: 60%;
    margin: 0px auto 20px auto;
    color: #666;
    font-size: 12px;
    code {
        font-style: normal;
    }
}


p.tags {
    font-size: 13px;
    a {
        font-size: 12px;
    }
}

.next-page {
    float: right;
    text-align: right;
    position: relative;
}

.prev-page {
    float: left;
}

.next-page,
.prev-page {
    background-color: #0e1012;
    font-size: 18px;
    font-weight: bold;
    padding: 15px 20px;
    text-transform: uppercase;
    width: 43%;
    span {
        color: #fff;
        transition: color 0.05s ease-in-out;
    }
    b {
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 11px;
        color: red;
        @media (max-width: 400px) {
            font-size: 10px;
            line-height: 1.5;
        }
    }
    @media (max-width: 767px) {
        width: 100%;
        padding: 0;
        height: 100%;
        span {
            font-size: 15px;
            line-height: 20px;
            display: block;
        }
    }
    span {
        @media (max-width: 400px) {
            font-size: 12px;
            line-height: 16px;
            display: block;
        }
    }
}


a:hover .next-page span,
a:hover .prev-page span {
    color: red;
    transition: color 0.05s ease-in-out;
}

.pagination {
    text-align: center;
    margin-top: 25px;
    p {
        font-size: 19px;
    }

    a {
        font-weight: bold;
    }
}
