/* ------------------------------------------------------------
	Review index
------------------------------------------------------------ */

#review-item-wrap {
	margin-bottom: 50px;
}

ul.reviews-index-list {
	margin-top: -30px;
	clear: both;
}

.reviews-index {
	h3 {
		text-align: left;
		font-size: 30px !important;
		margin-bottom: 20px !important;
	}
}

body.reviews-index {
	#content a {
		font-weight: bold
	}
	td {
		padding-bottom: 5px;
	}

	#content h3 {
		font-size: 20px;
		margin-bottom: 10px;
		a.header-setting {
			color: #999;
			font-weight: bold;
			text-transform: uppercase;
			font-size: 19px;
			margin-left: 5px;
		}
	}
}

.reviews-filter-wrap {
	display: flex;
	justify-content: center;
	margin-bottom: 1rem;
}

@media (max-width: 767px) {

	.reviews-index .entry-title-wide {
		display: none;
	}

	body.reviews-index #content h3 {
		font-size: 25px !important;
	}

}

.review-item {
	text-align: center;

	img {
		width: 100%;
		max-width: 100%;
	}
	img.img-portrait {
		max-height: 140px;
		width: auto;
	}
	.stars {
		display: block;
		color: gold;
		margin-top: 2px;
	}

	.review-item-date {
		font-style: italic;
		font-size: 17px;
		line-height: 22px;
		margin-top: 0px;
		margin-bottom: 0px;
		color: #999;
	}
	a {
		font-weight: bold;
		font-style: normal;
		font-size: 19px;
	}
		img {
			@media (max-width: 630px) {
				max-width: 100px;
				height: auto;
			}
		}
}

.entry-details .pre-review-label,
.review-item .pre-review-label {
	background-color: #0e1012;
	display: inline-block;
	color: #fff;
	border-radius: 4px;
	padding: 2px 7px;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 14px;
}

/*Review index tabs*/

.review-tabs {
	display: flex;
	margin: 20px 0;
	list-style: none;
	background-color: #777;
	border: 1px solid #ccc;
	margin-left: 0 !important;
	> li {
		position: relative;
		padding: 10px 7px;
		border-right: 1px solid #ccc;
		margin-bottom: 0 !important;
		flex: 1 1 auto;
		text-align: center;
	}
	> li:last-of-type {
		border-right: 0;
	}

	a {
		color: #fff;
		text-decoration: none !important;
		padding: 2px 8px;
		font-size: 14px;
	}

	li.active {
		background-color: #f9f9f9;
		 a {
			color: red !important;
			font-size: 17px;
			border:0;
		}

		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			left: calc(50% - 4px);
			bottom: -10px;
			border-style: solid;
			border-width: 10px 8px 0 8px;
			border-color: #f9f9f9 transparent transparent transparent;
		}
		&:before {
			left: calc(50% - 5px);
			border-width: 12px 10px 0 10px;
			border-color: #ccc transparent transparent transparent;
			bottom: -11px;
			margin-left: -1px;
			z-index: -1;
		}
	}

	a:hover {
		color: #ebebeb;
		text-decoration: none;
	}
}


/* FF positioning fix */

.tab-content {
	position: relative;
}

.tabs-pane {
	position: absolute;
	top: 0;
	display: none;
	@media (max-width: 767px) {
		min-height: 1000px;
		.loading {
			background: url('/images/photographyblog/loading.gif') center center no-repeat;
			min-width: 30px;
			min-height: 40px;
			margin: 20px auto;
		}
		table td {
			overflow: hidden;
			padding: 5px;
			img {
				width: 100%;
				max-width: 120px;
				height: auto;
			}
		}
	}
}


/*
.tabs-pane.active position needs !important below to override easytabs.js, as it
 applies original position of element (set as absolute above) to the element inline
 when it's displayed by the plugin.
*/

.tabs-pane.active {
	display: block;
	position: relative !important;
}


/*lazy loading spinner*/
.loading,
.reviews-index-list-load {
		height: 100px;
		width: 100%;
		background: #fff url(/images/photographyblog/loading.gif) center center no-repeat;
}

.section-search {
	margin-bottom: 20px;
	text-align: center;
	position: relative;
	padding-bottom: 30px;
	input {
			width: 70%;
			min-width: 280px;
			padding: 10px;
			display: block;
			margin: 0 auto;
			font-size: 16px;
			border: 1px solid #666;
	}
}

.filter-text {
		font-size: 13px;
		color: #999;
		margin-top: 10px;
		display: block;
		text-align: center;
		margin-bottom: -20px;
		&__count-text {
				display: none;
		}
		&__searching {
				display: inline-block;
				background: url(/images/photographyblog/loading.gif) no-repeat;
				padding-left: 20px;
		}
		&__reset a {
				display: inline-block;
				margin-left: 10px;
				text-decoration: none !important;
				padding: 2px 7px;
				background-color: #0e1012;
				color: #fff;
		}
		.filter-text__reset a:hover {
				color: red;
		}
}

.filter--count {
		display: none;
		margin-bottom: -30px;
}

.reviews-wrap {
	&.search-active {
			margin: -0px auto;
			max-width: 320px;
			ul.reviews-index-list {
					margin-top: 20px;
			}
			/*Enable list-style view for active live search results - uses styles from the m-w 400px MQ, prefixed with the id.class, which is added when search is active in the live search jQ script */
			br {
					display: none;
			}

			.review-item {
					width: 100%;
					float: none;
					clear: both;
					margin-left: 0px;
					text-align: left;
					.review-item a {
							font-size: 15px;
					}
					a img {
							width: 74px;
							height: auto;
							float: left;
							margin-bottom: 25px;
					}
					.review-item-title,
					.stars,
					.review-item-date {
							text-align: left;
							margin-left: 80px;
					}
			}
			/*TODO clearfix?*/
			div.review-item .stars,
			div.review-item .review-item-date {
					display: inline;
					margin: 0;
					float: none;
			}
			div.review-item .stars {
					margin-left: 5px;
			}

			li.review-item a::after {
					content: '';
					display: block;
			}
			li.review-item .review-item-title,
			li.review-item .stars,
			li.review-item .review-item-date {
					margin-left: 0px;
			}
			li.review-item .stars {
					margin-left: -8px;
			}
	}

}

.no-camera-results {text-align: center;}

@media (max-width: 479px) {

	.youtube-content div>table>tbody>tr>td {
		display: block;
		width: 100%;
	}

}