/* ------------------------------------------------------------
	Images
------------------------------------------------------------ */

p img {
	padding: 0;
	display: block;
	margin: 0 auto;
}

p img+em {
	text-align: center;
	display: block;
}

a img {
	border: none;
}

img.left {
	float: left;
	margin: 2px 15px 10px 0;
}

img.right {
	float: right;
	margin: 2px 0 10px 15px;
}

img.center {
	margin: 20px auto;
	display: block;
}

img[width="0"],
img[width="1"] {
	position: absolute;
}

svg {
	fill: currentColor;
	width: 1em;
	height: 1em;
}

.entry-list picture {
	display: block;
	margin-bottom: 0.5em;
	text-align: center;
	width: 100%;
}

@media (max-width: 767px) {


	#content .content-inner figure img,
	#content .content-inner p img {
		max-width: 100%;
		height: auto;
	}

}