/* ------------------------------------------------------------
    Footer
------------------------------------------------------------ */


#footer {
    text-align: center;
    padding: 20px 12px;
    border-top: 1px solid #ccc;
    margin: 0 50px;
    width: auto;
    p {
        color: #999;
        font-size: 16px;
        padding-top: 0px;
        margin-bottom: 10px;
    }

    a {
        color: #888;
        text-decoration: underline;
    }

    @media (max-width: 767px) {
        width: auto;
        margin: 0;
    }
}


.footer-leaderboard {
    margin: 10px auto 25px auto;
}