/* ------------------------------------------------------------
	Header
------------------------------------------------------------ */

#header {
	background: #0e1012;
	position: relative;
	z-index: 2;
	a {
		color: white;
		transition: color 0.05s ease-in-out;
		text-decoration: none;
		&:hover {
			color: red;
			transition: color 0.05s ease-in-out;
			text-decoration: none;
		}
	}

}

#header-wrap {
	position: relative;
	width: 100%;
}

#logo {
	padding: 0;
	width: calc(100% - 130px);
	@media (min-width: 768px) {
		margin-top: -0.5em;
		width: auto;
	}
	img {
		display: block;
		height: auto;
		width: 140px;
		@media (min-width: 768px) {
			width: 180px;
		}
	}
}

.leaderboard {
	background-color: #fff;
	float: right;
	height: 90px;
	margin-top: 8px;
	width: 728px;
	font-size: 0px;
}

/* Social icons */

#social {
	color: #fff;
	font-family: "Arial Narrow", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	font-size: 14px;
	line-height: 1;
	letter-spacing: 1px;
	text-transform: uppercase;
	padding: 10px 0 0 0;
	opacity: 1;
	transition: opacity 0.3s ease-out;
	text-align: right;
	width: 100%;
	a {
		color: #fff;
		display: inline-block;
		margin-left: 5px;
		padding-right: 1px;
		margin-right: 1px;
		letter-spacing: 0px;
		font-weight: bold;
		&:hover,&:focus {
			color: red;
			text-decoration: none;
		}
		&:first-child {
			margin-left: 0;
		}
		b {
			font-size: 18px;
		}

		&.email {
			border: 0;
		}
	}

	a svg {
		width: 12px;
		height: 12px;
	}

	.twitter svg {
		width: 16px;
	}

	.email svg {
		width: 18px;
	}

	.vdiv {
		display: none;
	}

	.newsletter {
		display: inline-block;
		width: auto;
		text-align: right;
		margin-top: -2px;
	}

	/* link to newsletter page */
	a.newsletter-link {
		border: 0;
		padding-right: 4px;
		margin-left: 5px;
	}

	/* link to newsletter CC sign up form */
	a.email-newsletter {
		color: red;
	}

	a.contact {
		margin-right: 0px;
		padding-right: 0px;
		b {
			display: none;
		}
	}

	.newsletter form {
		display: inline;
	}

	.newsletter {
		a {
			margin-right: 0px;
			margin-left: 6px;
		}
		input {
			border-width: 1px;
			position: relative;
			top: -1px;
			&.input {
				background-color: #0e1012;
				color: #eee;
				border: 1px solid #666;
				padding: 4px 3px;
				width: 130px;
			}

			&.submit {
				background-color: #0e1012;
				border: 2px solid #666;
				color: #eee;
				font: bold 12px/1 "Arial Narrow", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
				text-transform: uppercase;
				padding: 2px 1px;
				width: 75px;
			}
		}

	}


	a span {
		margin-right: 2px;
	}

}

.subscribe {
	display: none;
}


/* ------------------------------------------------------------
	Main menu
------------------------------------------------------------ */

.main-menu {
	max-height: 0;
	overflow: hidden;
	position: absolute;
	left: 0;
	top: 100%;
	transition: max-height .2s ease-out;
	width: 100%;
	>ul {
		background: #1a1a1a;
		list-style: none;
		margin: 0;
		box-shadow: -8px 0 8px rgba(0,0,0,.5);
		width: 100%;
	}

	a {
		display: block;
		padding: .75em 11px;
		line-height: 1em;
		font-size: 1em;
		color: #fff;
		text-decoration: none;
		border-bottom: 1px solid #383838;
		&:hover,
		&:focus {
			background: #333;
		}
	}

	li:first-child a {
		border-top: 1px solid #383838; /* add top border only for the first link */
	}
}

/* Menu icons */

.icon-home {
	width: 1em; height: 1em;
}

.menu-icon {
	cursor: pointer;
	display: inline-block;
	margin-right: 20px;
	padding: 34px 0 0;
	position: relative;
	user-select: none;
}
	.navicon {
		background: #FFF;
		display: block;
		height: 2px;
		position: relative;
		transition: background .2s ease-out;
		width: 28px;
	}
		.navicon:before,
		.navicon:after {
			background: #FFF;
			content: '';
			display: block;
			height: 100%;
			position: absolute;
			transition: all .2s ease-out;
			width: 100%;
		}
		.navicon:before {
			top: 8px;
		}

		.navicon:after {
			top: -8px;
		}


/* Menu btn */

.menu-btn {
	display: none;
}

#menu-btn:checked ~ .main-menu {
	max-height: 30em;
}
#menu-btn:checked	~ .menu-icon .navicon {
	background: transparent;
}
#menu-btn:checked	~ .menu-icon .navicon:before {
	transform: rotate(-45deg);
}
#menu-btn:checked	~ .menu-icon .navicon:after {
	transform: rotate(45deg);
}

#menu-btn:checked	~ .menu-icon:not(.steps) .navicon:before,
#menu-btn:checked	~ .menu-icon:not(.steps) .navicon:after {
	top: 0;
}


/*
	================================
	Mobile search
	================================
*/

.menu-search {
	color: #FFF;
	margin: 1em 0 0;
	label {
		cursor: pointer;
	}
	form {
		background: #000;
		max-height: 0;
		overflow: hidden;
		position: absolute;
		left: 0;
		top: 100%;
		transition: max-height .2s ease-out;
		width: 0;
		.flex {
			padding: 10px 20px;
			div {
				box-shadow: 0 0 1px rgba(255,255,255,.5);
				width: 100%;
			}
		}
	}
		input {
			font-size: 16px;
			width: calc(100% - 45px);
		}
		button {
			background-color: #000;
			border: 0;
			padding: 0 8px;
		}
}
	.icon-search {

		width: 1.5em; height: 1.5em;
		vertical-align: bottom;
	}

#menu-search-btn:checked ~ form {
	max-height: 5em;
	width: 100%;
	overflow: visible;
}


/*
	================================
	Larger screen styling
	================================
*/

@media (min-width: 768px) {

	.main-menu {
		display: block;
		max-height: none;
		overflow: visible;
		position: static;
		width: auto;

		ul {
			background: none;
			box-shadow: none;
			padding: 0;
			height: auto;
			width: auto;
		}

		ul {
			list-style-type: none;
			margin: 5px -5px 0 0px;
		}

		>ul {
			display: flex;
			justify-content: right;
			width: 100%;
		}
		li {
			color: #fff;
			font: bold 23px/1 "Arial Narrow", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
			text-transform: uppercase;
			&:first-child a {
				border-top: 0
			}
			&.drop-down {
				margin-right: 10px;
				padding-right: 10px;
				position: relative;
				z-index: 99;
				a {
					background: url(/images/photographyblog/nav-drop-arrow.gif) no-repeat 100% 60%;
					padding-right: 20px;
					padding-bottom: 10px;
				}
			}

		}
		li a {
			border: 0;
			font-size: 20px;
			color: #fff;
			transition: color 0.05s ease-in-out;
			&:hover {
				background-color: transparent;
				color: red;
				text-decoration: none;
			}
		}

		.drop-menu {
			height: auto;
			padding: 10px 0 0;
			margin: 0;
			z-index: 999;
			background-color: #fff;
			border: 0 solid #ccc;
			zoom: 1;
			visibility: hidden;
			opacity: 0;
			position: absolute;
			top: 44px;
			left: 0;
			transition: visibility 0s linear 0.2s, opacity 0.2s linear;
			text-align: left;
			li {
				display: block;
				margin: 0;
				margin-bottom: 8px;
				font-size: 18px;
				padding-bottom: 7px;
				color: #333;
				border-right: 0px;
				border-bottom: 1px solid #eee;
				&.last {
					border-bottom: 0px;
					margin-bottom: 0px;
				}

				img {
					float: left;
					margin-right: 10px;
				}

			}
				#header & {
					 a {
						background: none;
						border: 0;
						color: #2a3c4e;
						display: block;
						padding: 0 10px;
						 &:hover {
							color: red;
							transition: color 0.05s ease-in-out;
						}
					}
				}
		}

		li.drop-down {
			position: relative;
			&:hover {
				>a {
					color: red;
				}
			}
		}

		li.drop-down:hover {
			ul.drop-menu {
				visibility: visible;
				opacity: .97;
				transition-delay: 0s;
				position: absolute;
				top: 106%;
				left: 0;
				box-shadow: 0 0 6px rgba(0,0,0,0.2);
			}
		}

		ul.other-reviews-menu {
			width: 205px;
		}
		ul.last {
			margin-right: 0px;
		}

		ul li.header {
			font-size: 14px;
			color: #333;
			padding-bottom: 2px;
		}

		ul.drop-menu li.more {
			padding-top: 2px;
			margin-top: 2px;
			color: #999;
			a {
				font-style: italic;
				color: #666;
			}
		}

		/* Header search */

		.nav-search {
			border: 0;
			margin: 0 0 0 1.8em;
			padding: 0;
			align-self: center;
			.search {
				background-color: #0e1012;
				border: 2px solid #666;
				border-radius: 3px;
				color: #eee;
				font: bold 20px/1 "Arial Narrow", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
				text-transform: uppercase;
				height: 20px;
				padding: 4px;
				opacity: 1;
				width: 151px;
			}
		}

	}

}

@media (max-width: 767px) {

	.skyscraper {
			display: none;
	}

}