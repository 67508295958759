body.home-wide #content,
.home-wide .content-inner {
	width: 984px;
	@media (max-width: 767px) {
		width: auto;
	}
}

.home-wide .row {
	display: table;
}

@media (max-width: 767px) {

	.home {

		.notice {
				display: none;
		}

		h3 {
				text-align: center !important;
		}


		.content-block {
			width: 100%;
			padding: 10px;
			box-sizing: border-box;
			h4 a {
					line-height: 45px;
			}

			.entry,
			.secondary {
					float: none;
					width: 100% !important;
			}

			.entry.primary img {
					display: block;
					margin: 0 auto;
			}

			ul a {
					width: auto;
					margin: 0 0 0 5px !important;
			}

			.secondary ul li {
					width: auto;
					padding-bottom: 15px;
					margin-left: 100px !important;
					img {
							margin: -15px 10px 25px -100px !important;
					}
					small {
							margin-left: 5px !important;
					}
			}
			/*lens review variation*/
			&.lens-reviews .secondary ul li {
				margin-left: 70px !important;
				img {
						margin: -15px 10px 25px -70px !important;
				}
			}
			/*latest reviews*/
			.row.image-wrap {
					display: none !important;
			}
			a.mobile-only {
					margin-bottom: 5px;
			}
			h4 {
					text-align: center !important;
					margin-bottom: 5px;
			}
			/*a.mobile-img img {margin: 0 !important;}
		*/
			.entry {
				+.entry {
						margin-top: 20px;
				}
				.details {
					text-align: center;
					.twitter-share-button,
					.fb-like {
							display: none;
					}
				}

			}

			/*second secondary block*/
			&.latest-reviews-secondary .secondary+.secondary {
					margin-top: -15px !important;
			}
		}

		/*how to*/
		&.how-to p {
				float: none;
				width: auto;
				margin-right: 0 !important;
				margin-left: 110px;
				clear: both;
		}
		&.how-to img {
				float: left;
				margin: 0 10px 20px -110px !important;
		}

		.mobile-only.mpu-wrap {
				margin-top: -20px !important;
		}
		.latest-reviews-primary+.mobile-only.mpu-wrap {
				margin-top: -75px !important;
				margin-bottom: 75px !important;
		}

		.content-block a.mobile-only {
			margin-bottom: 5px;
		}

	} /* end .home */

	.home-wide {
		.mobile-only.mpu-wrap {
				margin-top: 10px !important;
		}
		.latest-reviews-primary+.mobile-only.mpu-wrap {
				margin-top: 10px !important;
				margin-bottom: 20px !important;
		}

	}

}


.hp-ad-full-width {
	text-align: center;
	margin-bottom: 50px;
}


.hp-section {
	margin-bottom: 50px;
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}

	@media (max-width: 767px) {
		margin-bottom: 0px;
		padding: 10px;
		.row {
				display: block;
				margin-bottom: 0 !important;
		}
		/*reset latest articles margin; no banner */
		&--latest-articles {
				margin-top: 0px;
		}

		&__header {
				margin-top: 10px;
		}
		&__header,
		&__header a {
				font-size: 26px;
				line-height: 28px;
		}
		/*hide images from double-entry grid*/
		.row.image-wrap {
				display: none;
		}

	}

}


/* Latest articles margin between banner, desktop only */

.hp-section--latest-articles {
	margin-top: 15px;
}

.hp-section .row {
	margin-bottom: 15px;
}


/*section headers*/

.hp-section__header {
	border-bottom: 1px solid #ccc;
	margin-bottom: 30px;
}

.hp-section__header,
.hp-section__header a {
	text-align: left;
	font-size: 22px;
	line-height: 24px;
	text-transform: uppercase;
	text-decoration: none !important;
	letter-spacing: -1px;
}


@media only screen and (max-width: 768px) {
	.hp-entry[style] {
		padding-top: 0 !important;
	}
}

/*excerpt entry*/

#content .hp-section-grid {
	margin-left: 0;
}

.hp-section-grid {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: repeat(auto-fill, minmax(276px, 1fr));
	grid-template-columns: repeat(auto-fill, minmax(276px, 1fr));
	grid-gap: 2rem;
	list-style-type: none;
	width: 100%;
}

@media only screen and (min-width: 768px) {
	.hp-entry-wrap {
		display: flex;
		.hp-section-grid {
			width: 66.66667%;
			grid-template-columns: repeat(auto-fill,minmax(270px,1fr));
		}
	}
}

	#content .hp-entry a {
	text-decoration: none;
	}
	.hp-entry img {
		height: auto;
		width: 100%;
	}

.hp-grid__card {
	display: flex;
	flex-direction: column;
	min-height: 100%;
	.img-portrait {
		display: block;
		margin: 0 auto;
		width: auto;
	}
}

/* Entry headers*/

.hp-entry__title {
	margin-bottom: 5px;
}

.hp-entry__title,
.hp-entry__title a {
	font-size: 36px;
	line-height: 1.2;
	display: block;
	text-decoration: none !important;
}

.hp-entry__minihead {
	color: red;
	display: block;
	margin-right: 3px;
	padding-right: 7px;
	padding-bottom: 5px;
	font-size: 19px;
}


/* Entry content */

.hp-entry {
	p {
		margin-bottom: 10px !important;
	}
	img {
		margin-bottom: 0;
	}
	.details {
		margin-top: auto;
		color: #999;
		margin-right: 5px;
		text-align: left;
		line-height: 2;
		font-size: 17px;
		a {
			text-decoration: none !important;
		}
		.sm-vdiv {
			display: block;
			font-size: 0;
			color: #fff;
		}
	}

	.fb-like {
		display: inline-block;
		vertical-align: middle;
		/*margin-left: -7px;*/
		margin-top: 2px;
	}

	.twitter-share-button {
		vertical-align: bottom;
	}

}


@media (max-width: 767px) {

	.hp-entry-wrap,
	.hp-entry-wrap .hp-entry,
	.hp-entry {
		float: none;
		display: block;
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		margin-bottom: 0;
	}

	.hp-entry-wrap .hp-entry,
	.hp-entry {
		margin-bottom: 40px;
	}


	.hp-entry,
	.hp-entry__title,
	.hp-entry .details {
		text-align: center;
	}
	.hp-entry p {
		text-align: left;
	}

	.hp-entry__minihead {
		display: none;
		&.mobile-only {
			display: block;
			text-align: center;
			padding: 0px 0 20px 0;
			margin-right: 0;
			font-weight: bold;
		}
	}

	.hp-ad-block,
	.hp-entry.entry-2 {
		display: none;
	}

	.hp-entry-list {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		width: 100%;
		&--lens-reviews li {
			padding-left: 70px;
		}
		&--lens-reviews img {
			margin-left: -70px;
		}
		img.entry-image.img-portrait {
			max-height: 175px;
			width: initial;
		}
	}


}

@media (max-width: 499px) {

    .hp-entry-list {
        display: block;
	    li {
	        width: 100%;
	        overflow: hidden;
	        padding-right: 0;
	    }
    }

}


#content .hp-section {
	.hp-entry-list {
		margin: 50px 0 0;
		list-style-type: none;
		@media only screen and (min-width: 768px) {
			margin-top: 0;
			padding-left: 50px;
			width: 33.3333%;
		}
		li {
			margin: 0 0 25px;
			font-size: 20px;
		}
	}
}


.hp-entry-list-img {
	width: 100px;
	img {
		display: block;
		margin: 0 auto;
	}
}

.hp-entry-list-text {
	line-height: 1.25;
	width: calc(100% - 120px);
}

#content .hp-entry-list a {
	display: flex;
	font-weight: normal;
	text-decoration: none;
}

.hp-entry-list li.pop-1 {
	display: none;
}

/* Modifier for lens reviews*/

.hp-entry-list--lens-reviews {
	li {
		margin: 0 0 25px 85px !important;
	}
	img {
		margin: -15px 0 25px -80px;
	}
}


/* entry datestamp */

.hp-datestamp {
	color: red;
	display: inline-block;
	margin-right: 3px;
	padding-right: 7px;
	border-right: 1px solid #ddd;
	/*font-size: 14px;*/
	font-size: 16px;
	vertical-align: text-top;
}

.hp-datestamp--list {
	/*font-size: 12px;*/
	font-size: 16px;
	display: block;
	border: 0;
}

.hp-section .row.image-wrap {
	margin-bottom: 10px;
}

.hp-entry--image {
	padding: 0 0 0 0%;
	text-align: center;
	vertical-align: bottom;
	display: table-cell;
	float: none;
	a {
		margin-left: -20px;
	}
	&:first-child {
		padding: 0 0% 0 0 !important;
	}
}

.hp-entry--image img {
		max-height: 250px;
		width: auto;
}


.hp-entry-list img {
		max-height: 85px;
		width: auto;
}

.hp-ad-block {
	width: 33%;
	float: right;
}

/* Afilliate block*/

.afl-block {
		padding: 17px 20px 5px 20px;
		background-color: #eee;
		margin-bottom: 30px;
		border-radius: 5px;
}

.afl-block-button-wrap {
		text-align: center;
}

.afl-block-button {
		font-weight: bold;
		background-color: darkred;
		padding: 10px 15px;
		border-radius: 5px;
		display: inline-block;
		color: #fff;
		text-decoration: none !important;
		margin: 5px 0;
		border: 1px solid rgba(255, 255, 255, 1);
		box-shadow: 0px 0px 5px 3px rgba(255, 255, 255, 0.5);

		span:before {
			@media (max-width: 767px) {
				display: block;
				content: "";
			}

		}
}