body.review #content h2 {
	clear: both;
}

body.sample-images #content h2 b,
body.review #content h2 b {
	display: block;
	font-size: 20px;
	color: #999;
	letter-spacing: 0px;
	text-transform: uppercase;
}

body.static-page h2 {
	margin-top: 30px;
}


/* Need these to clear next page blocks */

.adsense-med-rect {
	display: none;
	float: left;
	margin: 10px 10px 5px 0;
}

.mpu-wrap {
	float: left;
	margin: 0 10px 10px 0;
}

.section-start:target {
	height: 65px;
	display: block;
	padding-bottom: 20px;
}

.content-inner h3+p img p {
	margin-top: 10px;
}

#content .review-roundup h3.entry-header {
	a {
		text-decoration: none;
	}
}

@media (max-width: 767px) {

	.content-inner {
		.mpu-wrap {
			display: none;
			&.mobile-only {
				display: block;
				float: none;
				text-align: center;
				margin: 10px -12px 20px -12px;
			}
			ins {
				float: none;
				margin: 0 auto;
			}
		}
		table {
			td {
				font-size: 17px;
				img {
					box-sizing: border-box;
					border-right: 2px solid #fff;
					body.image_quality & {
						max-width: 200px;
						width: 100%;
						height: auto;
						&[width="550"] {
							max-width: 550px;
							width: 100%;
							height: auto;
						}
						/* for portrait imgs, e.g. macro */
						&[height="200"][width^="1"] {
							max-width: 200px;
							max-height: 200px;
							width: auto;
						}
					}
				}
				+td img {
					border-left: 2px solid #fff;
					border-right: 0;
				}
			}
			body.introduction &,
			body.product_images & {
				img {
					max-width: 550px;
					width: 100%;
					height: auto;
				}
			}

			/* Product images */
			body.product_images & {
				&[width="550"] {
					width: 100%;
				}
				&[width="400"] {
					width: 100%;
					td img {
						width: 100%;
						max-width: 550px;
					}
				}

			}
		}
	}

	body.introduction {

		.content-inner h3 {
			text-align: left;
		}
		.prereview-image {
			width: 100%;
			height: auto;
		}


	}
	body.introduction,
	body[class*="sharpness_"] {
		p img {
			width: 100%;
			height: auto;
			max-width: 550px;
		}
		/* Adjust smaller 2 x 2, and 2 x 4 images*/
		.content-inner table td img[width="200"] {
			max-width: 200px;
			width: 100%;
			height: auto;
			box-sizing: border-box;
		}
	}

	/* Specifications */

	.specifications-table {
		width: 100%;
		overflow: hidden;
		h2,
		h3 {
			text-align: left;
		}
		table {
			width: 100%;
			.content-inner & {
				th {
					font-size: 15px;
					padding: 20px 10px;
				}
				td {
					font-size: 15px;
					&+td {
						word-break: break-all;
						hyphens: auto;
					}
				}

			}
		}
	}

	/* Rivals */

	body.rivals {
		.content-inner {
			h3 {
				text-align: left;
			}
			.to-left {
				margin-top: 5px;
			}

		}

	}

	/* Roundup */

	#content .review-roundup h3.entry-header {
		text-align: left;
	}

}

@media (max-width: 499px) {

	table.sample-images-gallery,
	.sample-images .content-inner table {
		tr, td, th, tbody {
			display: block;
			width: 100%;
		}

	}

}